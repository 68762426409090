<template>
  <div class="home">
    <div class="hero-section">
      <div class="content">
        <h1>Hi, I'm <span class="highlight">Joseph</span></h1>
        <h2 class="typewriter">Full Stack Developer</h2>
        <p class="intro">
          Building modern web experiences with Vue.js, Node.js, and cutting-edge
          technologies
        </p>
        <div class="cta-buttons">
          <router-link to="/projects" class="cta-primary"
            >View Projects</router-link
          >
          <a href="#contact" class="cta-secondary">Get in Touch</a>
        </div>
      </div>
    </div>

    <div class="skills-section">
      <h2>Technical Skills</h2>
      <div class="skills-grid">
        <div class="skill-card" v-for="skill in skills" :key="skill.name">
          <div class="skill-icon">{{ skill.icon }}</div>
          <h3>{{ skill.name }}</h3>
          <div class="skill-bar">
            <div class="progress" :style="{ width: skill.level + '%' }"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="stats-section">
      <div class="stat-card">
        <h3>Years Experience</h3>
        <div class="stat-value">3+</div>
      </div>
      <div class="stat-card">
        <h3>Projects Completed</h3>
        <div class="stat-value">10+</div>
      </div>
      <div class="stat-card">
        <h3>Technologies</h3>
        <div class="stat-value">15+</div>
      </div>
    </div>

    <div class="tech-section">
      <h2>Technology Stack</h2>
      <div class="tech-categories">
        <div class="tech-category">
          <h3>Frontend</h3>
          <div class="tech-list">
            <span
              v-for="tech in technologies.frontend"
              :key="tech"
              class="tech-item"
              >{{ tech }}</span
            >
          </div>
        </div>
        <div class="tech-category">
          <h3>Backend</h3>
          <div class="tech-list">
            <span
              v-for="tech in technologies.backend"
              :key="tech"
              class="tech-item"
              >{{ tech }}</span
            >
          </div>
        </div>
        <div class="tech-category">
          <h3>Tools</h3>
          <div class="tech-list">
            <span
              v-for="tech in technologies.tools"
              :key="tech"
              class="tech-item"
              >{{ tech }}</span
            >
          </div>
        </div>
        <div class="tech-category">
          <h3>Design</h3>
          <div class="tech-list">
            <span
              v-for="tech in technologies.design"
              :key="tech"
              class="tech-item"
              >{{ tech }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      skills: [
        { name: "Vue.js", level: 90, icon: "⚡" },
        { name: "JavaScript", level: 95, icon: "🚀" },
        { name: "Node.js", level: 76, icon: "🛠" },
        { name: "CSS/SCSS", level: 85, icon: "🎨" },
        { name: "REST APIs", level: 75, icon: "🔌" },
        { name: "Git", level: 70, icon: "📦" },
      ],
      technologies: {
        frontend: ["Vue.js", "JavaScript", "HTML5", "CSS3/SCSS", "React"],
        backend: ["Node.js", "Express", "Python", "MongoDB"],
        tools: ["Git", "Docker", "AWS", "Webpack", "Jest"],
        design: ["Figma", "Adobe XD", "UI/UX Principles"],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: var(--bg-color);
  color: var(--text-color);
}

.tech-section {
  padding: 4rem 2rem;
  background: var(--card-bg);

  h2 {
    text-align: center;
    margin-bottom: 3rem;
    color: var(--text-color);
  }
}

.tech-categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.tech-category {
  background: var(--bg-color);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h3 {
    color: #42b983;
    margin-bottom: 1.5rem;
  }
}

.tech-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .tech-item {
    background: var(--hover-bg);
    color: var(--text-color);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
  }
}

.hero-section {
  position: relative;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: #42b983;
    border-radius: 50%;
    opacity: 0.1;
    animation: float1 15s infinite;
  }

  &:after {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    background: #42b983;
    border-radius: 50%;
    opacity: 0.1;
    animation: float2 20s infinite;
  }

  .content {
    max-width: 800px;

    h1 {
      font-size: 3.5rem;
      margin-bottom: 1rem;

      .highlight {
        color: #42b983;
      }
    }

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
      color: var(--text-color);
      opacity: 0.9;
    }

    .intro {
      font-size: 1.2rem;
      margin-bottom: 2rem;
      line-height: 1.6;
    }
  }
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;

  a {
    padding: 1rem 2rem;
    border-radius: 8px;
    font-weight: bold;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }

  .cta-primary {
    background: #42b983;
    color: white;
    text-decoration: none;
  }

  .cta-secondary {
    border: 2px solid #42b983;
    color: #42b983;
    text-decoration: none;
  }
}

.skills-section {
  padding: 4rem 2rem;
  background: var(--card-bg);

  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.skill-card {
  background: var(--bg-color);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;

  .skill-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  .skill-bar {
    background: var(--hover-bg);
    height: 8px;
    border-radius: 4px;
    overflow: hidden;

    .progress {
      height: 100%;
      background: #42b983;
      transition: width 1.5s ease;
    }
  }
}

.stats-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;

  .stat-card {
    background: var(--card-bg);
    padding: 2rem;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      margin-bottom: 1rem;
      color: var(--text-color);
      opacity: 0.8;
    }

    .stat-value {
      font-size: 2.5rem;
      font-weight: bold;
      color: #42b983;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes float1 {
  0% {
    transform: translate(-50%, -50%) scale(1);
    left: 10%;
    top: 20%;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.5);
    left: 90%;
    top: 80%;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    left: 10%;
    top: 20%;
  }
}

@keyframes float2 {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    left: 90%;
    top: 30%;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    left: 20%;
    top: 70%;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    left: 90%;
    top: 30%;
  }
}
.skill-card {
  animation: slideIn 0.5s ease forwards;
}
</style>
