import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../views/Projects.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/demo/color-generator",
    name: "ColorGeneratorDemo",
    component: () => import("../views/demos/ColorGenerator.vue"),
  },
  {
    path: "/demo/crypto-dashboard",
    name: "CryptoDashboardDemo",
    component: () => import("../views/demos/CryptoDashboard.vue"),
  },
  {
    path: "/demo/car-shop",
    name: "CarShopDemo",
    component: () => import("../views/demos/CarShop.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
