<template>
  <div id="app" :class="{ 'dark-mode': isDark }">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/projects">Projects</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/contact">Contact</router-link>
    </nav>
    <button class="theme-toggle" @click="toggleDarkMode">
      {{ isDark ? "☀️" : "🌙" }}
    </button>
    <div class="router-view-container">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --bg-color: #ffffff;
  --text-color: #2c3e50;
  --link-color: #2c3e50;
  --active-link: #42b983;
  --card-bg: #ffffff;
  --border-color: #dddddd;
  --nav-bg: #ffffff;
  --input-bg: #ffffff;
  --modal-bg: #ffffff;
  --hover-bg: #f5f5f5;
  --scrollbar-bg: #f5f5f5;
  --scrollbar-thumb: #42b983;
  --scrollbar-thumb-hover: #3aa876;
}

.dark-mode {
  --bg-color: #1a1a1a;
  --text-color: #ffffff;
  --link-color: #e0e0e0;
  --active-link: #42b983;
  --card-bg: #2d2d2d;
  --border-color: #404040;
  --nav-bg: #2d2d2d;
  --input-bg: #333333;
  --modal-bg: #2d2d2d;
  --hover-bg: #363636;
  --scrollbar-bg: #2d2d2d;
  --scrollbar-thumb: #42b983;
  --scrollbar-thumb-hover: #3aa876;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 5px;
  transition: background 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  transition: background-color 0.3s ease;
  height: 100vh;
  overflow-y: hidden;
}

#app {
  font-family: "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
  min-height: 100vh;
  background-color: var(--bg-color);
}

.router-view-container {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 80px);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: var(--link-color);
    text-decoration: none;

    &.router-link-exact-active {
      color: var(--active-link);
    }
  }
}

.theme-toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background: var(--card-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
}
</style>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      isDark: (state) => state.darkMode.isDark,
    }),
  },
  methods: {
    toggleDarkMode() {
      this.$store.commit("toggleDarkMode");
    },
  },
  mounted() {
    if (this.isDark) {
      document.documentElement.classList.add("dark-mode");
    }
  },
};
</script>
