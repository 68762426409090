import { createStore } from "vuex";

export default createStore({
  state: {
    darkMode: {
      isDark: localStorage.getItem("darkMode") === "true",
    },
  },
  mutations: {
    toggleDarkMode(state) {
      state.darkMode.isDark = !state.darkMode.isDark;
      localStorage.setItem("darkMode", state.darkMode.isDark);
      document.documentElement.classList.toggle("dark-mode");
    },
  },
});
